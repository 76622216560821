@font-face {
  font-family: museo sans rounded;
  src: url("../src/SharedComponents/MuseoSansRounded-300.otf")
}


body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /*opacity: 0.3*/
}

.thinHeading{
  font-weight: 300;
}

.iconHeading{
  padding-left: 10px;
}

.heroSection{
  font-family: museo sans rounded, sans-serif;
  padding-top: 2%;
  padding-bottom: 4%;
}

.updatedLandingHero{
  padding-top: 80px;
  display: grid;
  grid-template-columns: 40% 60%;
  font-family: museo sans rounded, sans-serif;
}

.updatedLandingHero h1{

}

.updatedLandingHeroMobile{
  padding-top: 80px;
  display: grid;
  grid-template-columns: 100%;
  font-family: museo sans rounded, sans-serif;
}

.updatedLandingHeroMobile img {
  display: none;
}

.servingGlobalAPIHeader{
  font-family: museo sans rounded, sans-serif;
  font-size: 350%;
}

a.nostyle:link, a.nostyle:visited {
  text-decoration: inherit;
  color: inherit;
}

.signUpHeroButton{
  border: 0;
  background: none;
  box-shadow: none;
  cursor: pointer;
  background-color: #52bd95;
  border-radius: 15px;
  /*margin-top: 40%;*/
  /*margin-top: 100px;*/
  width: 325px;
  height: 115px;
  font-size: 34px;
  /*margin-left: 10%;*/
  /*float: right;*/
  color: white;
  font-weight: 800;
  font-family: museo sans rounded, sans-serif;
}

.updatedLandingHeroMobile .signUpHeroButton{
  text-align: center;
}

.updatedLandingHeroMobile h1{
  text-align: center;
}

.signUpHeroButton:hover{
  box-shadow: 0 8px 32px 0 rgba(0,0,0,0.2);
}

.updatedLandingSocialProof{
  margin-top: 100px;
}

.customerTestimonials{
  width: 50%;
  margin: 0 auto;
  background-color: whitesmoke;
  text-align: center;
  padding: 30px 0 30px 0;
  border-radius: 25px;
  box-shadow: 0 8px 32px 0 rgba(0,0,0,0.2);
  margin-bottom: 50px;
}

.customerTestimonialLink > h4{
  color: black;
}




@media only screen and (min-width: 600px) {
  .heroDiv{
    background: url("../src/SharedComponents/BannerBackground.svg") no-repeat;
    background-size: 100% auto;
  }
}

@media only screen and (max-width: 600px) {
  .heroDiv{
    /*background: url("../src/SharedComponents/BannerBackground.svg") no-repeat;*/
    /*background-size: 100% auto;*/
    background: linear-gradient(90deg, rgba(177,198,14,1) 0%, rgba(0,212,255,1) 100%);
  }
}


.heroDiv > div{
  padding-top: 75px;
  padding-bottom: 75px;
  text-align: center;
  font-family: museo sans rounded, sans-serif;
}

.heroDiv h1{
  font-size: 350%;
}

.uploadOuterSection{
  background-color: #00b200;
  /*background-color: #99e099;*/
  color: white;
  /*border-radius: 5px;*/
  height: 200px;
  /*padding-top: 1%;*/
  /*padding-left: 1%;*/
  padding: 5px;
  /*display: flex;*/
  /*flex-direction: column;*/
  /*justify-content: center;*/
  /*resize: vertical;*/
  /*overflow: auto;*/
  margin-bottom: 2.5%;
  cursor: pointer;
}

.uploadInnerSection{
  /*border: 1px dashed #7F7F7F;*/
  border: 1px dashed rgba(0, 0, 0, 0.15);
  /*border: 2px dashed whitesmoke;*/
  /*background-color: #009900;*/
  background-color: #008e00;
  height: 100%;
  width: 100%;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /*resize: vertical;*/
  /*overflow: auto;*/
}

.uploadLabel{
  height: 100%;
  width: 100%;
  text-align: center;
}

.uploadIcons{
  font-size: 300%;
  font-weight: 300;
}

.lightgray{
  background-color: lightgrey;
}

.uploadProgressBar{
  width: 50%;
  text-align: center;
  margin: 0 auto;
}

.fileDropTarget{
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  resize: both;
  margin-bottom: 2%;
}

.fileDropSource{
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: 1px solid white;
  border-radius: 5px;
  background-color: white;
  height: 90%;
  width: 90%;
}

.fileDropSourceDrag{
  visibility: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: 1px solid white;
  border-radius: 5px;
  background-color: white;
  height: 90%;
  width: 90%;
  -ms-transform: rotate(20deg); /* IE 9 */
  -webkit-transform: rotate(20deg); /* Safari */
  transform: rotate(20deg);
}

.linkStyle{
  color: cornflowerblue;
  cursor: pointer;
}

.linkStyle:hover{
  text-underline: blue;
  color: blue;
}

.app{
  /*display: flex;*/
  min-height: 100vh;
  /*flex-direction: column;*/
}

.roundedButton{
  border-radius: 40px;
}

.downloadFileIndex{
  /*background-color: #ffdb99;*/
  /*border: 1px solid #ffdb99;*/
  border-radius: 5px;
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: auto auto auto;
  grid-template-areas:
          "header header"
          "fileName removeDuplicates"
          "downloadButton downloadButton";
}

.mergedFileNameDiv{
  grid-area: fileName;
  justify-self: end;
  margin-right: 10%;
  align-self: center;
}

.removeDuplicatesDiv{
  grid-area: removeDuplicates;
  margin-left: 5%;
  align-self: center;
  font-size: 120%;
}

.downloadButtonDiv{
  margin-top: 25px;
  margin-bottom: 25px;
  grid-area: downloadButton;
}

.downloadHeader{
  padding-top: 12px;
  padding-bottom: 20px;
  text-align: center;
  grid-area: header;
}

.editOptionDiv{
  padding-bottom: 10px;
}

.importSheetOptions{
  padding-top: 10px;
  padding-bottom: 10px;
}

.wordwrap{
  word-break: break-all;
  word-wrap: break-word;
}

.semanticBorder{
  margin: 0em;
  max-width: 100%;
  -webkit-box-flex: 1;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  outline: none;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-radius: 0.28571429rem;
  -webkit-transition: border-color 0.1s ease, -webkit-box-shadow 0.1s ease;
  transition: border-color 0.1s ease, -webkit-box-shadow 0.1s ease;
  transition: box-shadow 0.1s ease, border-color 0.1s ease;
  transition: box-shadow 0.1s ease, border-color 0.1s ease, -webkit-box-shadow 0.1s ease;
  -webkit-box-shadow: none;
  box-shadow: none;
  color: rgba(0, 0, 0, 0.8);
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 5px;
}

.focusBorder{
  border-color: #85B7D9;
  background: #FFFFFF;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.pricingPage{
  display: grid;
  grid-template-columns: 33% 33% 34%;
  grid-template-rows: auto;
  grid-template-areas:
          "freePricingCard paidPricingCard businessPricingCard";
  min-height: 100vh;
  grid-column-gap: 25px;
}

.freePricingCard{
  grid-area: freePricingCard;
}

.paidPricingCard{
  grid-area: paidPricingCard;
  margin: 0 auto;

}

.businessPricingCard{
  grid-area: businessPricingCard;
}

.pricingCard{
  /*padding-left: 30%;*/
  /*padding-right: 30%;*/
  padding-top: 10%;
}

.desc {
  list-style-type: none;
  border: 1px solid #eee;
  margin: 0;
  padding: 0;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.desc li {
  border-bottom: 1px solid #eee;
  padding: 10px;
  text-align: center;
}

.closeIconClass{
  height: 25px;
  width: 25px;
  float: right;
  margin: 0 auto;
  cursor: pointer;
  text-align: center;
}

.closeIconClass:hover{
  background-color: #DCDCDC;
  border-radius: 50%;
}

.closeIconClass > i{
  padding: 0;
}

#radioOutputFormatGroup{
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: 200px 200px auto;
  grid-column-gap: 5px;
  grid-template-areas: "jsonRow matrix jsonColumn";
}

.jsonRowRadioDiv{
  grid-area: jsonRow;
}

.matrixRadioDiv{
  grid-area: matrix;
}

.jsonColumnRadioDiv{
  grid-area: jsonColumn;
}

#privateDiv{
  text-align: center;
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: auto auto;
  grid-template-areas: "privateCheckbox privateKey";
}

#privateCheckboxDiv{
  grid-area: privateCheckbox;
}

#privateKeyDiv{
  grid-area: privateKey;
}

.footerShareGrid{
  display: grid;
  grid-template-columns: auto auto auto auto auto;
  grid-template-rows: auto;
  grid-template-areas: "twitter facebook reddit linkedin email";
}

.socialMediaButton{
  cursor: pointer;
}
.footerEmptySpace{
  grid-area: footerEmpty;
}
#twitterButton{
  grid-area: twitter;
}

#facebookButton{
  grid-area: facebook;
}

#redditButton{
  grid-area: reddit;
}

#linkedinButton{
  grid-area: linkedin;
}

#emailButton{
  grid-area: email;
}

.switchAPIType{
  display: grid;
  grid-template-columns: 100px auto;
  grid-template-rows: auto;
  grid-template-areas:
          "getData postData";
  grid-column-gap: 10px;
}

.getAPIType{
  grid-area: getData;
}

.postAPIType{
  grid-area: postData;
}

.docsPageIndex{
  min-height: 100vh;
  display: grid;
  grid-template-columns: 20% 80%;
  grid-template-rows: auto;
  grid-template-areas:
          "docsMenu docsDisplay";
}

.docsMenu{
  grid-area: docsMenu;
  border-right: 1px solid lightgray;
  /*background-color: #DCDCDC;*/
  padding-top: 30px;
}

.docsDisplay{
  grid-area: docsDisplay;
  padding-top: 30px;
  padding-left: 50px;
  padding-right: 50px;
}

.urlBox{
  border: 1px solid lightgray;
  border-radius: 5px;
  width: 100%;
  background-color: #203354;
  color: white;
  padding: 10px;
}

.baseURLBox{
  border: 1px solid lightgray;
  border-radius: 5px;
  width: 100%;
  background-color: #203354;
  color: white;
  /*padding: 7px;*/
}

.fileDesc input{
  background-color: #203354;
  color: white;
  border: none;
  width: 340px;
  font-family: Consolas,monospace;

}

.urlBoxLanding{
  border: 1px solid lightgray;
  border-radius: 5px;
  width: 100%;
  background-color: #ccccff;
  padding: 10px;
}

.parameterName{
  font-weight: 800;
}

.parameterType{
  font-size: 90%;
  color: gray;
  padding-left: 5px;
}

.parameterSecondLine{
  /*margin-top: -15px;*/
  font-size: 80%;
  display: block;
}

.parameterRequired{
  color: red;
}

.parameterDesc{
  margin-top: -10px;
}

#parameterHeading{
  margin-bottom: -10px;
  margin-top: 20px;
}

.parameterDefault{
  font-style: italic;
}

.getParam{
  padding-right: 10px;
}

.getResponse{
  padding-left: 30px;
}

.getResponseHeading{
  border: 1px solid gray;
  border-radius: 5px;
  background-color: #E8E8E8;
  padding: 10px;
  color: black;
  padding-bottom: 30px;

}

.getResponseBox{
  width: 350px;
  border: 1px solid gray;
  border-radius: 5px;
  margin-top: 5px;
}

.getResponseBoxLanding{
  width: 100%;
  border: 1px solid gray;
  border-radius: 5px;
  margin-top: 5px;
}

#getResponseCodesHeading{
  margin-bottom: -10px;
  margin-top: 20px;
}

.getResponseCodes{
  padding: 10px;
}

.responseCodeStatus{
  font-size: 105%;
  background-color: #f5f5f5;
  padding: 5px;
  font-weight: 800;
}

.responseCodeParam{
  font-size: 90%;
}

.codeSuccess{
  color: green;
}

.responseCodeDesc{
  display: block;
  margin-top: 1px;
  padding-left: 5px;
  font-size: 85%;
}

.responseCodeLine{
  margin-top: -5px;
  margin-bottom: -5px;
}

.codeFail{
  color: red;
}

#guidesIndex{
  min-height: 100vh;
}

#contactGuide{
  padding: 8px;
  border: 1px dashed gray;
  border-radius: 5px;
}

#formCode{
  width: 95%;
  margin-bottom: 40px;
}

.dropbtn {
  border-radius: 5px;
  background-color: white;
  color: black;
  padding: 10px 8px 10px 8px;
  font-size: 14px;
  border: none;
  cursor: pointer;
  width: 100%;
}

.customDropdown {
  position: relative;
  display: inline-block;
  width: 100%;
  border-radius: 5px;
  border: 1px solid lightgrey;
  /*box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);*/
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  border-radius: 0 0 5px 5px;
  min-width: 160px;
  width: 100%;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 999;
}

.dropdown-content div {
  color: black;
  padding: 6px 8px;
  text-decoration: none;
  display: block;
}

.dropdown-content div:hover {background-color: #f1f1f1}

.customDropdown:hover .dropdown-content {
  display: block;
}

.customDropdown:hover .dropbtn {
  background-color: whitesmoke;
  border-radius: 5px 5px 0 0;
}

.segment{
  background: #fff;
  border-radius: 2px;
  padding: 20px;
}

.segment-raised{
  box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
}

.segment-margin{
  background: #fff;
  border-radius: 2px;
  margin: 1rem;
  padding: 20px;
}

.segment-no-padding{
  background: #fff;
  border-radius: 2px;
}


.row-hover:hover{
  background-color: whitesmoke;
}

.free-pricing-column{
  background-color: lightblue;
  border-radius: 5px;
}

.pricingFeaturesDiv{
  /*padding-top: 25px;*/
  font-size: 125%;
}

.pricingGrid{
  padding-left: 40px;
  padding-right: 40px;
  text-align: center;
}

.subheading{
  color: gray;
}

.pricingHeader, .pricingCost{
  margin-bottom: -5px;
}

.pricingCost{
  margin-top: 10px;
}

.pricingCTA{
  font-size: 130%;
  padding-top: 15px;
  padding-bottom: 40px;
  font-weight: bold;
}

.pricingBox{
  padding-top: 30px;
  padding-bottom: 30px;
}

.proPricingBox{
  border-radius: 10px;
  background-color: whitesmoke;
}

.teamPricingBox{
  padding-top: 0px;
  background-color: #203354;
  border-radius: 10px;
  color: white;
}

.customPricingBox{
  background-color: black;
  color: white;
  border-radius: 10px;
}

.pricingBestValueTag{
  background-color: orange;
  color: white;
  height: 25px;
  margin-bottom: 5px;
  padding-top: 3px;
  border-radius: 10px 10px 0 0;
  font-weight: bold;
}

.featureItem{
  font-size: 125%;
}

.faqSummary{
  border-bottom: 1px dotted;
  cursor: pointer;
  /*float: left;*/
  /*text-align: left;*/
}

.pricingDots{
  margin-top: 100px;
}

.pricingFAQs{
  margin-top: 50px;
  margin-bottom: 400px;
}

.pricingFAQDiv{
  padding-left: 20px;
  padding-right: 10px;
}

@media only screen and (max-width: 600px){
  .pricingFAQDiv{
    margin-top: 30px;
    margin-bottom: 30px;
  }
}

.inputLabel{
  font-size: 115%;
  font-weight: bold;
}

.guidesDisplay{
  padding-bottom: 250px;
  /*width: 90%;*/
}

.guidePost p{
  font-size: 125%;
  margin-bottom: 40px;
}

.guidePost li{
  font-size: 125%;
  margin-bottom: 10px;
}

.guidePost figure{
  margin-bottom: 40px;
}

.bold{
  font-weight: bold;
}

/*.guidePost blockquote{*/
  /*background: #f9f9f9;*/
  /*border-left: 10px solid #ccc;*/
  /*margin: 1.5em 10px;*/
  /*padding: 0.5em 10px;*/
/*}*/

/*.guidePost blockquote:before {*/
  /*color: #ccc;*/
  /*font-size: 4em;*/
  /*line-height: 0.1em;*/
  /*margin-right: 0.25em;*/
  /*vertical-align: -0.4em;*/
/*}*/

/*.guidePost > blockquote p {*/
  /*display: inline;*/
  /*font-size: 130%;*/
  /*font-style: italic;*/
/*}*/

.tableHeaderDesc{
  color: green;
  font-weight: 300;
}

#columnInfoSaveBox{
  border: 1px solid lightgray;
  background-color: whitesmoke;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 20px;
}

#nameColDescSub, #descColDescSub, #typeColDescSub{
  font-size: 105%;
  color: gray;
  font-weight: 500;
}

#nameColDescSub{

}

#descColDescSub{

}

#typeColDescSub{

}

.guidesMenu{
  border: 1px solid lightgrey;
  border-radius: 5px;
  padding: 10px;
}

.guidesMenuDesc{
  color: gray;
}

.codeTheoryBox{
  border: 1px lightgrey solid;
  border-radius: 5px;
  padding: 10px;
  background-color: whitesmoke;

}

.codeExampleBox{
  border: 1px green solid;
  border-radius: 5px;
  padding: 10px;
  background-color: #ceffce;
}

.codeExampleBox > b{
  color: green;
  display: block;
}

.codeInvalidBox > b{
  color: red;
  display: block;
}

.codeInvalidBox{
  border: 1px red solid;
  border-radius: 5px;
  padding: 10px;
  background-color: #ffd8d8;
}

.replaceText{
  font-size: 250%;
  color: red;
  display: block;
}

.fileIDBox{
  border: 1px slategray solid;
  border-radius: 5px;
  padding: 5px;
  background-color: whitesmoke;
  /*box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);*/
  /*height: 75px;*/
}

.fileNameBox{
  /*height: 75px;*/
}

.privateButtonBg{
  background-color: white;
  border-radius: 5px;
  border: 1px lightgrey solid;
  padding: 10px 5px 5px 5px;
  font-weight: bold;
}

.fileSummaryActionTypeRadio{
  padding-right: 15px;
  padding-left: 5px;
  padding-top: 2px;
  cursor: pointer;
}

input[type="radio"]{
  cursor: pointer;
}

.fileSummaryCodeToolbar{
  margin-top: 10px;
  margin-bottom: 5px;
  /*border: 1px solid lightgrey;*/
  border-radius: 10px;
  padding: 10px 10px 10px 2px;
  /*height: 40px;*/
}

.queryQuickstartRulesDiv{
  border: 1px solid lightgrey;
  border-radius: 5px;
  padding: 10px;
}

.jsonDisplayBox{
  background-color: lightgrey;
  border: 1px lightgrey solid;
  border-radius: 5px 5px 0 0;
  padding: 10px;
}

.clickDiv{
  cursor: pointer;
  border: 1px solid lightgrey;
  text-align: center;
  padding: 10px;
  border-radius: 10px;
}

.clickDiv:hover{
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
}

.fileDescDiv{
  display: grid;
  grid-template-columns: 85% auto;
  grid-template-rows: auto;
  grid-template-areas: "descTextArea descButtons";
  padding: 5px;
}

.fileDescTextArea{
  grid-area: descTextArea;
}

.fileDescTextArea textarea{
  height: 30px;
}

.fileDescTextArea p{
  background-color: whitesmoke;
}

.fileDescButtons{
  text-align: right;
  grid-area: descButtons;
}

.fileDesc{
  background-color: #203354;
  /*border-radius: 5px;*/
  margin-left: 2px;
}

.shadowDiv{
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
}

.custmoAccordion{
  border: 1px solid lightgrey;
  background-color: whitesmoke;
}

.customAccordionHeader{
  cursor: pointer;
  width: 100%;
  text-align: left;
  outline: none;
  border: none;
}

.customAccordionHeader:focus {outline:0;}


.customAccordionHeaderText{
  grid-area: accordionHeaderText;
  padding-left: 15px;
}

.customAccordionHeaderIcon{
  grid-area: accordionHeaderIcon;
  text-align: center;
}


.customAccordionBody{
  padding: 0 15px 15px 15px;
}

.quickstartAccordionPrompt{
  color: green;
  float: right;
  /*font-weight: 300;*/
  display: block;
  font-style: italic;
  font-size: 75%;
  padding-top: 5px;
}

.privateButtonOpaque{
  opacity: 0.3;
  cursor: none;
}



.privateButtonUnlock{
  float: right;
  cursor: pointer;
  color: green;
  opacity: 1;
}

.dateRangePickerCustom{
  /*border: 1px solid lightgrey;*/
  /*border-radius: 15px;*/
}

.customImage{
  cursor: pointer;
  /*margin-top: 0px;*/
  margin-bottom: 30px;
}

.startModalRow{
  text-align: center;
}

.startModalRow span {
  font-weight: bold;
  font-size: 120%;
}


.footerIndex{
  /*background-color: #00AE00;*/
  background-color: #0081ff;
}

.fullFooterBox h3{
  padding-bottom: 15px;
  font-weight: 300;
}

.fullFooter{
  padding: 50px 10px 40px 10px;
}

.apiSocialFooterBox img{
  padding-top: 20px;
  padding-bottom: 20px;
  float: left;
}


.apiSocialFooterBox span{
  float: left;
  margin-bottom: 15px;
}

.footerItem{
  cursor: pointer;
  text-decoration: none;
  font-size: 130%;
  color: white;
  display: block;
  margin-bottom: 15px;
}

.footerItem:hover{
  color: black;
  text-decoration: none;
}

#feedbackDiv{
  width: 250px;
  font-size: 90%;
  background-color: whitesmoke;
}

.feedbackButtons{
  margin-top: 10px;
}

.feedbackResponseMessage{
  padding-top: 10px;
  width: 250px;
  text-align: center;
  font-size: 75%;
}

.helpIndex{
  min-height: 100vh;
}

.helpViewIndex {
  padding: 50px;
  margin-bottom: 250px;
}

.faq{
  padding-bottom: 15px;
}

#pricingPopupBody > table {
  border-collapse: collapse;
  /*border: 1px solid lightgrey;*/
}

#pricingPopupBodyEvenRow td, #pricingPopupBodyOddRow td{
  border-left: 1px solid lightgrey;
  border-right: 1px solid lightgrey;
  text-align: center;
}

#pricingPopupBodyEvenRow > td:nth-child(3), #pricingPopupBodyOddRow > td:nth-child(3){
  background-color: whitesmoke;
}
#pricingPopupBodyEvenRow > td:nth-child(4), #pricingPopupBodyOddRow > td:nth-child(4){
  background-color: #203354;
  color: white;
}
#pricingPopupBodyEvenRow > td:nth-child(5), #pricingPopupBodyOddRow > td:nth-child(5){
   background-color: black;
   color: white;
}

#pricingPopupBodyEvenRow{
  background-color: whitesmoke;
}

/*#pricingPopupBodyEvenRow, #pricingPopupBodyOddRow{*/
  /*border: 1px solid lightgrey;*/
/*}*/

#pricingPopupBodyCTA > td{
  padding: 5px;
  margin-top: 5px;
  border: 1px solid lightgrey;
  border-radius: 5px;
  text-align: center;
}

#pricingPopupBodyHeader, #pricingPopupBodyPricing{
  text-align: center;
}

#pricingPopupBodyPricing > td{
  border-left: 1px solid lightgrey;
  border-bottom: 1px solid lightgrey;
  border-right: 1px solid lightgrey;
  padding: 0 5px 5px 5px;
}

#pricingPopupBodyHeader > th{
  border-left: 1px solid lightgrey;
  border-top: 1px solid lightgrey;
  border-right: 1px solid lightgrey;
  padding: 5px 5px 0 5px;
  /*border-radius: 5px 5px 0 0;*/
}

#pricingPopupBodyHeader > th:nth-child(3), #pricingPopupBodyPricing > td:nth-child(3){
  background-color: whitesmoke;
}

#pricingPopupBodyHeader > th:nth-child(4), #pricingPopupBodyPricing > td:nth-child(4){
  background-color: #203354;
  color: white;
}

#pricingPopupBodyHeader > th:nth-child(5), #pricingPopupBodyPricing > td:nth-child(5){
  background-color: black;
  color: white;
}

.faqEmailUs{
  text-align: center;
  margin-bottom: 200px;
}

#navbarHelpLinks{
  padding-top: 10px;
  width: 100px;
}

#navbarHelpLinks div{
  margin-bottom: 20px;
}

.supportFormLabel{
  font-size: 110%;
}

.requiredLabel{
  padding-left: 3px;
  padding-right: 3px;
  color: red;
}

.inputError{
  color: red
}

#supportFormClearAllButton{
  float: right;
  border-radius: 12px;
  font-size: 80%;
  cursor: pointer;
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
}

#subjectCharLeft{
  color: gray;
  font-style: italic;
}

#supportUploadFiles{
  background-color: #0088ff;
  color: white;
}

#supportUploadFiles:hover {
  box-shadow: 0 12px 16px 0 rgba(0,0,0,0.24),0 17px 50px 0 rgba(0,0,0,0.19);
}

.buttonRaw {
  border: none;
  padding: 5px 8px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 12px;
  margin: 4px 2px;
  cursor: pointer;
  -webkit-transition-duration: 0.4s; /* Safari */
  transition-duration: 0.4s;
}

#planTypeDisplay{
  display: grid;
  grid-template-columns: auto auto;
}

#planTypeDetails{
  float: right;
}

#pricingOptionsGrid{
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: auto;
  grid-template-areas: "pricingOptionsText pricingOptionsDropdown";
}

#pricingOptionsText{
  grid-area: pricingOptionsText;
  text-align: right;
  margin-top: 7px;
  margin-right: 3px;
  font-weight: bold;
}

#pricingOptionsDropdown{
  grid-area: pricingOptionsDropdown;
}

#profilePricingPlanRow{
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: 30% 70%;
}

#planTypeUpgradeButton{
  margin-top: -10px;
}

.carousel{
  display: grid;
  grid-template-columns: 5% 90% 5%;
  grid-template-rows: auto 20px;
  grid-template-areas: "carouselLeft carouselContent carouselRight"
  "carouselLeft carouselIndicator carouselRight";
}

.carouselLeft{
  grid-area: carouselLeft;
  position: relative;
}

.carouselLeft:hover{
  background-color: rgba(128,128,128, 0.1);
}

.carouselContent{
  grid-area: carouselContent;

}

.carouselRight{
  grid-area: carouselRight;
  position: relative;
}

.carouselRight:hover{
  background-color: rgba(128,128,128, 0.1);
}

.carouselRight > div{
  float: right;
  padding-left: 5%;
  /*font-size: 250%;*/
}

.carouselIndicator{
  grid-area: carouselIndicator;
  /*margin: 0 auto;*/
  width: 100%;
  bottom: 0;
  position: absolute;
  text-align: center;
}

.carouselIndicator > div{
  text-align: center;
  padding-top: 50px;
}

#carouselLeftArrow{
  position: absolute;
  top: 50%;
  text-align: right;
}

#carouselRightArrow{
  position: absolute;
  top: 50%;
}

.featureModalSlide{
  /*border: 1px solid lightgrey;*/

}

.apiFeatures{
  margin-top: 150px;
  margin-bottom: 150px;
}

.featureModalHeader{
  text-align: center;
  /*font-size: 30px;*/
  font-size: 300%;
  font-family: museo sans rounded, sans-serif;
  display: block;
  /*text-shadow: 1px 1px 2px black;*/
  margin-bottom: 25px;
}

.featureModalSubHeader{
  /*text-shadow: 1px 1px 2px black;*/
  font-size: 20px;
  font-weight: 600;
}

.logoGrid{
  font-family: museo sans rounded, sans-serif;
  margin-top: 50px;
  margin-bottom: 100px;
}

.logoGrid > h1{
  font-size: 300%;
  text-align: center;
  margin-bottom: 50px;
  /*animation-name: fadeInUpCustom;*/
  /*animation-duration: 5s;*/
}

.logoGrid > div{
  position: absolute;
  float: right;
}

.logoGrid > div span{
  display: block;
  padding-left: 250px;
  font-size: 190%;
}

.logoGrid > img{
  padding-left: 200px;
  padding-right: 200px;
  /*display: grid;*/
  /*grid-template-columns: 33% 33% 34%;*/
  /*grid-template-rows: auto;*/
  /*grid-template-areas: "logo1 logo2 logo3";*/
  /*grid-column-gap: 10px;*/

}

.logoGrid image{
  text-align: center;
}

.logoGrid > span{
  position: absolute;
  /*top: 150px;*/
  /*top: 0;*/
  right: 250px;
  margin-top: 375px;
  padding: 15px 30px 15px 30px;
  border-radius: 15px;
  font-size: 200%;
  font-weight: bold;
  border: 1px solid lightgrey;
  background: linear-gradient(30deg, rgb(252,252,200) 5%, rgb(0,128,0) 92%);
  color: white;
}

.gridLogo1{
  grid-area: logo1;
}

.gridLogo2{
  grid-area: logo2;
}

.gridLogo3{
  grid-area: logo3;
}

.gridLogo1, .gridLogo2, .gridLogo3{
  text-align: center;
}


.featureModalSimpleAPIExampleTable{
  margin-bottom: 25px;
  display: grid;
  grid-template-columns: 25% 75%;
  grid-column-gap: 25px;
}


.featureModalSimpleAPIExampleTableValues, .featureModalSimpleAPIExampleTableRequest{
  border: 1px solid lightgrey;
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
}

.featureModalSimpleAPIExampleTableRequest{
  width: 85%;
}

#featureTable th{
  background-color: whitesmoke;
}

#featureTable td, th{
  border: 1px solid lightgrey;
  padding: 7px;
}

#featureTable{
  margin-top: 10px;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
}

.centerTable{
  margin-left: auto;
  margin-right: auto;
}

#apiFeatureModalURLBox{
  border: 1px solid lightgray;
  border-radius: 5px;
  width: 100%;
  background-color: #203354;
  color: white;
  padding: 10px;
  font-size: 15px;
}

#flashingQuery{
  animation: UPGRADE-PRIVATE-BLINK 2s infinite;
  font-weight: bold;
}

/*@keyframes UPGRADE-PRIVATE-BLINK{*/
/*0%		{ background-color: #c4ffc4;}*/
/*25%		{ background-color: #89ff89;}*/
/*50%		{ background-color: #4eff4e;}*/
/*75%		{ background-color: #14ff14;}*/
/*100%	    { background-color: #00d800;}*/
/*}*/

@keyframes UPGRADE-PRIVATE-BLINK{
  0%		{ color: #c4ffc4;}
  25%		{ color: #00d800;}
  50%		{ color: #c4ffc4;}
  75%		{ color: #00d800;}
  100%	    { color: #c4ffc4;}
}

#featureModalCodeOuterDiv{
  margin-bottom: 50px;
}

#featureModalAPIWave{
  /*position: absolute;*/
}

#featureModalAPICode{
  /*position: absolute;*/
}

.featureCTAHeroGrid{
  display: grid;
  grid-template-columns: 20% 60% 20%;
  grid-template-rows: auto;
  grid-template-areas: "featureCTAHeroGridCol1 featureCTAHeroGridCol2 featureCTAHeroGridCol3";
  /*height: 300px;*/
}

.featureCTAHeroGridCol1{
  grid-area: featureCTAHeroGridCol1;
  min-height: 0;
}

.featureCTAHeroGridCol2{
  grid-area: featureCTAHeroGridCol2;
  padding-top: 20px;
}

.featureCTAHeroGridCol3{
  grid-area: featureCTAHeroGridCol3;
  min-height: 0;
}

.featureCTAHero{
  background: url("./InfoPages/HowItWorks/Features/Blob1.svg") no-repeat;
}

.needAuthModalBody{
  background: url("./Authentication/UserPages/Data.svg") no-repeat;
}

.featureCTAHeroText{
  padding-top: 30px;
  text-align: center;
  padding-bottom: 30px;
}

.featureCTASignUp{
  text-align: center;
  /*margin-top: 60px;*/
  /*margin-bottom: 80px;*/
  z-index: 999;
  /*position: absolute;*/
}

.featureCTAKnowMore{
  text-align: center;
  padding-top: 50px;
  font-size: 200%;
  bottom: 0;
}

#featureCTASignUpButton{
  padding-left: 100px;
  padding-right: 100px;
  cursor: pointer;
}

#ctaLeftGrid{
  display: grid;
  grid-template-rows: 25% 25% 25% 25%;
  grid-template-columns: 25% 25% 25% 25%;
  grid-template-areas: "ctaLeft1 ctaLeft2 ctaLeft2 ctaLeft3"
  "ctaLeft4 ctaLeft5 ctaLeft6 ctaLeft7"
  "ctaLeft8 ctaLeft8 ctaLeft9 ctaLeft10"
  "ctaLeft11 ctaLeft12 ctaLeft13 ctaLeft14";
  height: 100%;
}

#ctaLeft2{
  background-color: #00d084;
  border-radius: 0 0 100% 100%;
  height: 50%;
}

#ctaLeft8{
  background-color: #00d084;
  border-radius: 0 100% 100% 0;
  width: 60%;
}

#ctaRightGrid{
  display: grid;
  grid-template-rows: 25% 25% 25% 25%;
  grid-template-columns: 25% 25% 25% 25%;
  grid-template-areas: "ctaRight1 ctaRight2 ctaRight3 ctaRight4"
  "ctaRight5 ctaRight6 ctaRight6 ctaRight7"
  "ctaRight8 ctaRight9 ctaRight10 ctaRight11"
  "ctaRight12 ctaRight13 ctaRight13 ctaRight14";
  height: 100%;
}
#ctaRight13{
  background-color: #00d084;
  border-radius: 100% 100% 0 0;
  /*height: 50%;*/
  bottom: 0;
}

#ctaRight7{
  background-color: #00d084;
  border-radius: 100% 0 0 100%;
  /*width: 60%;*/
}

#crudModalGrid{
  margin-top: 150px;
  margin-bottom: 150px;
  padding-left: 200px;
  padding-right: 200px;
  display: grid;
  grid-template-columns: 40% 20% 40%;
}

#crudModalCreateDiv, #crudModalUpdateDiv, #crudModalDeleteDiv, #crudModalReadDiv{
  padding: 30px;
}

#crudModalCreateDiv{
  background: url("./InfoPages/HowItWorks/Features/BlobCreate.svg") no-repeat;
}

#crudModalUpdateDiv{
  background: url("./InfoPages/HowItWorks/Features/BlobUpdate.svg") no-repeat;
  background-position: center;
}

.rightTable{
  margin-left: auto;
}

.featureModalReadJSONTable{
  margin-top: 10px;
  margin-left: auto;
  font-size: 90%;
  background-color: #11ab73ff;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  color: white;
  font-weight: bold;

}

.featureModalReadJSONTableTopRowCell{
  padding-top: 25px;
}

.featureModalReadJSONTableBottomRowCell{
  padding-bottom: 25px;
}

.featureModalReadJSONTableLeftCell{
  padding-left: 10px;
}

.featureModalReadJSONTableRightCell{
  padding-right: 10px;
}

#crudModalGridMiddleColumn{
  position: relative;
}

#crudCenter{
  position: absolute;
  top: 35%;
}

#crudCenter > h1{
  text-align: center;
}

.crudCenterIcon{
  text-align: center;
  display: block;
  color: orangered;
}


#crudCenterTextAndArrows{
  display: grid;
  grid-template-columns: 10% auto 10%;
  /*position: relative;*/
}

.crudCenterSideArrows{
  position: relative;
}

.crudCenterSideArrowsIcon{
  top: 40%;
  position: absolute;
}

.crudCenterText{
  text-align: center;
  font-size: 300%;
  font-family: museo sans rounded, sans-serif;
}

.featuresIndex{
  min-height: 100vh;
}

.featuresBlock{
  padding-top: 50px;
  padding-bottom: 50px;
}

.featuresPageFeatureTableLeft{
  display: grid;
  grid-template-columns: 30% 70%;
  grid-template-rows: auto;
  grid-template-areas: "featuresPageFeatureTableMenu featuresPageFeatureTableDisplay";
}

.featuresPageFeatureTableRight{
  display: grid;
  grid-template-columns: 70% 30%;
  grid-template-rows: auto;
  grid-template-areas: "featuresPageFeatureTableDisplay featuresPageFeatureTableMenu";
  margin: 0px 175px 0px 175px;
}

.featuresPageFeatureTableMenu{
  grid-area: featuresPageFeatureTableMenu;
  border-right: 1px solid lightgrey;
  padding: 20px;
}

.featuresPageFeatureTableDisplay{
  grid-area: featuresPageFeatureTableDisplay;
  padding: 20px;
}

.featuresPageFeatureTableLeft, .featuresPageFeatureTableRight{
  border: 1px solid lightgrey;
  border-radius: 10px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
}

.rounded-div-border{
  border: 1px solid lightgrey;
  border-radius: 10px;
}

.click-hover-div{
  cursor: pointer;
}

.click-hover-div:hover{
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
}

.featuresPageCrudBox{
  text-align: center;
  background-color: whitesmoke;
}

.simpleInterfaceBox{
  /*margin-bottom: 50px;*/
}

.simpleInterfaceBox h1{
  font-size: 450%;
}

.landingIcon{
  /*box-shadow: 0 12px 16px 0 rgba(0,0,0,0.24),0 17px 50px 0 rgba(0,0,0,0.19);*/
}

.simpleInterfaceBoxBody{
  /*margin-top: 25px;*/
  /*bottom: 0;*/
  /*position: absolute;*/
  margin-bottom: 10px;
  padding-left: 5px;
  padding-right: 5px;
  text-align: center;
  font-size: 150%;
}

#exitModalFooterDiv{
  margin-bottom: 5px;
  /*padding-top: 10px;*/
  display: grid;
  grid-template-columns: 40% 60%;
  border-bottom: 1px solid lightgrey;
}

#exitModalFooterUndoToAction{
  /*float: left;*/
  /*bottom: 0;*/
  /*position: absolute;*/
}

#exitModalFooterUndoToAction > h6{
  /*top: 0;*/
  /*bottom: 0;*/
  /*position: absolute;*/
  /*padding-top: 15px;*/
}

#exitModalFooterLeaveTour{
  /*float: right;*/
  text-align: right;
}

#exitModalFooterLeaveTour > label{
  cursor: pointer;
}

#exitModalFooterLeaveTour > h6{
  color: red;
}

#actionModalBody{
  margin-top: 10px;
}

#actionModalBodyToolbar{
  display: grid;
  grid-template-columns: 50% 50%;
}

#actionModalBodyToolbarButtons{
  text-align: right;
}

#actionModalBodyToolbarButtons span{
  display: block;
}

/*.app *:not(.tutorialHighlighted){*/
  /*filter: blur(3px);*/
/*}*/

.tutorialHighlighted{
  /*background-color: whitesmoke;*/
  z-index: 500;
  position: relative;
  box-shadow: 0 12px 16px 0 rgba(0,0,0,0.24),0 17px 50px 0 rgba(0,0,0,0.19);
  background-color: whitesmoke;
  /*opacity: 1;*/
}

.tutorialHighlighted1{
  z-index: 501;
}

.actionIndex{
  background-color: #203354;
  color: white;
  box-shadow: 0 12px 16px 0 rgba(0,0,0,0.24),0 17px 50px 0 rgba(0,0,0,0.19);
  padding: 10px;
  border-radius: 10px;

}

.howDoesThisWork{
  text-align: center;
  margin: 125px 50px 0px 50px;
  font-family: museo sans rounded, "sans-serif";
}

.landingCTASignUp{
  text-align: center;
  margin-top: -2px;
}

#landingCTASignUpButton{
  cursor: pointer;
  background-color: green;
  color: white;
  padding: 15px 75px 15px 75px;
  font-size: 175%;
  font-weight: 400;
  /*box-shadow: 0 12px 16px 0 ,0 17px 50px 0;*/
  border-radius: 40px;
  transition: all 0.5s;
}

#landingCTASignUpButton span {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.5s;
}

#landingCTASignUpButton span:after {
  content: '\00bb';
  position: absolute;
  opacity: 0;
  top: 0;
  right: -20px;
  transition: 0.5s;
}

#landingCTASignUpButton:hover span {
  padding-right: 25px;
}

#landingCTASignUpButton:hover span:after {
  opacity: 1;
  right: 0;
}

.pricingHeroText{
  font-family: museo sans rounded, sans-serif;
  font-weight: bold;
  text-align: left;
}

.pricingPageImageOuter{
  background: url("LandingPage/Illustrations/MakeItRain.svg") no-repeat;
  /*height: 80%;*/
  width: 100%;
  /*padding-bottom: -20px;*/
  background-position: center;
}

.pricingPageImage{
  visibility: hidden;
  padding-bottom: 15px;
}

@media only screen and (max-width: 600px){
  .pricingHeroText{
    text-align: center;
    padding-bottom: 20px;
  }

  .pricingPageImageOuter{
    display: none;
  }

  /*.faqSummary{*/
    /*display: none;*/
  /*}*/

  .pricingBoxDisplay{
    display: none;
  }

  .pricingBox{
    margin-bottom: 25px;
  }
}

@media only screen and (min-width: 600px){
  .summaryMobileID{
    display: none;
  }
}

.pricingHaveMoreQuestions{
  background: url("./InfoPages/HowItWorks/Features/Blob1.svg") no-repeat;
  height: 250px;
  background-position: right top;
  background-size: 125%;
}

.pricingHaveMoreQuestionsText{
  text-align: center;
  padding-top: 15%;
}

.pricingDots > div > div > hr{
  background-color: lightgrey;
}

#featuresIndexHero{
  margin-top: 50px;
  background-size: 100% auto;
  background-repeat: no-repeat;
  position: relative;
}

#featuresIndexHeroButton{
  bottom: 75px;
  left: 150px;
  position: absolute;
  /*z-index: 150;*/
  /*padding-top: 10%;*/
}
#featuresIndexHero > img {
  /*position: relative;*/
  /*z-index: 125;*/
}

#featuresIndexHeroText{
  position: absolute;
  top:25px;
  width: 50%;
  text-align: center;
  font-family: museo sans rounded, sans-serif;
}

#featuresIndexHeroText > h1{
  /*font-size: 200%;*/
  font-weight: 900;
}

@keyframes fadeInUpCustom {
  from {opacity: 0; transform: translate3d(0, 100%, 0)}
  to {opacity: 1; transform: none}
}

.featureAPI{
  background: url("../src/InfoPages/Features/FeaturesAPI.svg") no-repeat;
  background-position: 50%;
  margin-top: 150px;
  padding: 0 200px 0 200px;
  margin-bottom: 150px;
}

.dashboardFeatureSubHeading{
  font-family: museo sans rounded, sans-serif;
  font-size: 150%;
  font-weight: bold;
}


.navLink:hover{
  /*font-weight: bold;*/
  border-bottom: 5px solid green;
  margin-bottom: 3px;
}

.navbarAuthDiv{
  /*background-color: rgba(0, 0, 0, 0.5);*/
  background-color: black;
}

.navbarMobileButton{
  cursor: pointer;
}

.mobileFeaturesDiv{
  font-family: museo sans rounded, "sans-serif";
  margin: 50px 0 25px 0;
  padding-bottom: 30px;
  border-bottom: 1px solid lightgrey;
}

.museoHeader{
  text-align: center;
  font-family: museo sans rounded, "sans-serif";
  padding-bottom: 50px;
}

@media only screen and (max-width: 600px){
  .app{
    margin: 0 30px 0 30px;
  }
}

.useCaseCard{
  font-family: museo sans rounded, "sans-serif";
  padding: 25px 10px 25px 10px;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  margin-top: 25px;
  border-radius: 10px;
  background-color: whitesmoke;
  position: relative;
  margin-left: 40px;
  /*cursor: pointer;*/
}

.useCaseCard:hover{
  box-shadow: 0 8px 32px 0 rgba(0,0,0,0.2);
}

.useCaseCard img{
  /*height: 50%;*/
  width: 40%;
  margin: 0 auto;
}

.useCaseCardBg{
  background-color: orangered;
  padding-top: 20px;
  border-radius: 10px;
  padding-bottom: -40px;
  position: relative;
  /*position: absolute;*/
}

.useCaseCardBg1{
  background-image: linear-gradient(to right, green, greenyellow, yellow);
}

.useCaseCardBg2{
  background-image: linear-gradient(to bottom, greenyellow, yellow, green);
}

.useCaseCardBg3{
  background-image: linear-gradient(to bottom right, yellowgreen, greenyellow, green);
}

.featuresButtonDiv{
  /*height: 660px;*/
  background: url("../src/SharedComponents/LandingUseCases/FooterBg.svg") no-repeat;
  background-size: cover;
}

.learnFeaturesLandingBtn{
  text-align: center;
}

.postParagraphText{
  font-size: 16px;
  margin-bottom: 20px;
}

.whatIsAnAPIButton{
  margin: 15px 0 0 25px;
  /*margin-left: 20px;*/
  /*display: block;*/
  /*margin-bottom: -25px;*/
}

.whatIsAnAPIButton > a {
  padding: 15px;
  /*font-size: 125%;*/
  box-shadow: 0 4px 8px rgba(0,0,0,0.19), 0 0px 4px rgba(0,0,0,0.23);
  border-radius: 25px;
  background-image:  linear-gradient(to right, yellowgreen, green);
  color: whitesmoke;
  font-weight: bold;
}

.apiSpreadsheetsText{
  font-weight: bold;
  /*color: green;*/
}

.googleEmail{
  font-weight: bold;
  /*color: cornflowerblue;*/
}

.caseStudyIndex{
  min-height: 100vh;
  margin: 50px;
  margin-bottom: 450px;
}

.columnNamesTutorial{
  border: 1px solid lightgrey;
  border-radius: 10px;
  padding: 20px;
  text-align: left;
  margin-top: 10px;
  margin-bottom: 20px;
  background-color: whitesmoke;
}

.fixColumnNamesBox{
  padding: 10px;
  border: 1px solid darkgray;
  border-radius: 10px;
  background-color: white;
}

.fixColumnNamesBox > h5{
  text-align: center;
}

.fixColumnNames{
  padding: 25px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.19), 0 0px 4px rgba(0,0,0,0.23);
  border-radius: 10px;
  border: 1px solid red;
  background-color: whitesmoke;
}

.fixColumnNames > h3{
  text-align: center;
  padding-bottom: 10px;
}

.fixColumnNamesTableHeaderRow td{
  border: 1px solid white;
  padding: 7px;
  font-weight: bold;
  background-color: lightgrey;
  text-align: center;
}

.fixColumnNamesTableBodyRow td {
  text-align: center;
  padding: 10px;
  border: 1px solid white;
  background-color: #203354;
  color: white;
}

.fixColumnNamesTable{
  margin: 0 auto;
}

.fixColumnNamesTableDiv{
  padding-top: 15px;
  padding-bottom: 15px;
}

.datasetIndex{
  min-height: 100vh;
}

.datasetRow{
  border-top: 1px solid lightgrey;
  width: 75%;
  margin: 0 auto;
  /*padding-top: 15px;*/
  /*padding-bottom: 15px;*/
}

.datasetRowBody{
  margin-top: 50px;
  margin-bottom: 150px;
}

.datasetDownloadButton{
  background-color: green;
  height: 100%;
  padding: 10px 8px 8px 10px;
  text-align: center;
  color: whitesmoke;
  cursor: pointer;
}

.datasetDownloadButton > span{
  font-size: 450%;
}

.datasetDownloadButton > p {
  font-size: 120%;
  font-weight: bold;
}

.datasetDownloadButton:hover{
  background-color: #00bb00;
}

.datasetRow:hover{
  background-color: whitesmoke;
}

.datasetRowLink{
  text-decoration: none;
  color: black;
}

.datasetRowLink:hover{
  text-decoration: none;
  color: blue;
}

.details-picture{
  height: 150px;
  width: 150px;
}

.predictorHeaderCell, .predictorCell{
  background-color: lightgrey;
  /*color: white;*/
}

.detailMetadataTag{
  /*font-weight: 900;*/
  /*font-size: 130%;*/
}

.detailMetadataValue{
  font-size: 115%;
}

.privacyAndTerms p{
  margin-bottom: 125px;
}

.promoIndex{
  min-height: 100vh;
}

.promoPricing{
  padding-bottom: 20px;
  /*padding-top: 20px;*/
  /*padding-right: 50px;*/
}

.promoPricingBoxes{
  border: 1px solid lightgrey;
  border-radius: 15px;
  padding: 10px;
  text-align: center;
}

.promoCurrency{
  text-align: center;
  padding-bottom: 50px;
}

.promoPaymentIndex{
  text-align: center;
  margin: 50px auto 150px auto;
  width: 50%;
  border: 1px solid lightgrey;
  padding: 15px;
  border-radius: 15px;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
}

@media only screen and (max-width: 600px) {
  .promoPaymentIndex {
    text-align: center;
    margin: 50px auto 150px auto;
    width: 100%;
    border: 1px solid lightgrey;
    padding: 15px;
    border-radius: 15px;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  }
}

.promoTimerCountdown{
  text-align: center;
  border: 1px solid lightgrey;
  border-radius: 50px;
  padding: 15px;
  background: linear-gradient(90deg, rgba(233,93,6,1) 0%, rgba(208,54,15,1) 35%, rgba(255,171,0,1) 100%);
  color: whitesmoke;
}

.promoCountdownLabel{
  font-size: 16px;
}

.blogHero{
  padding-top: 100px;
  padding-bottom: 100px;
  text-align: center;
  background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(130,214,130,1) 100%);
  /*color: white;*/
}

.blogHero > h1{
  font-size: 450%;
  font-weight: 300;
}

.tutorialsPreviewDiv{
  margin-right: 50px;
  margin-bottom: 250px;
  text-align: center;
}

@media only screen and (min-width: 600px) {
  .tutorialsDisplayIndex {
    width: 50%;
    margin: 0 auto;
    margin-bottom: 150px;
    margin-top: 50px;
  }
}

@media only screen and (max-width: 600px) {
  .tutorialsDisplayIndex {
    margin: 5px 10px 150px 10px;
  }
}
/*.guidePost{*/
  /*position: relative;*/
/*}*/
.tutorialShare{
  border: 1px solid lightgrey;
  border-radius: 15px;
  padding: 25px;
  /*height: 100px;*/
  /*width: 50px;*/
  box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
  float: left;
  margin: 10px 0 0 50px;
  position: fixed;
  text-align: center;
}

.privateToggleTutorialSpan{
    border: 1px solid lightgrey;
    border-radius: 25px;
    padding: 15px 15px 15px 15px;
}

.commentSection{
  margin-top: 75px;
  border-top: 1px solid lightgrey;
  padding-top: 25px;
}

.emailUnsubscribeIndex{
  min-height: 100vh;
  text-align: center;
  margin-top: 100px;
}

.emailUnsubscribeIndex > h4{
  margin-bottom: 50px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 23px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 17px;
  width: 17px;
  left: 3px;
  bottom: 3px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

.toggleButton:checked + .slider {
  background-color: #2196F3;
}

.toggleButton:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

.toggleButton:checked + .slider:before {
  -webkit-transform: translateX(17px);
  -ms-transform: translateX(17px);
  transform: translateX(17px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}